import { PartialItem } from '@directus/sdk';
import { directus, ScenarioData, TaskData } from '../services/directus';

let scenarioData: undefined | PartialItem<ScenarioData>[] | null;
let taskData: undefined | PartialItem<TaskData>[] | null;

const initializeData = async () => {
	const scenarioNumber = document.getElementById('root')?.getAttribute('scenario');
	const validatedNumber = scenarioNumber && parseInt(scenarioNumber);
	if(validatedNumber){
		await directus.items('scenarios').readMany(validatedNumber ? {	
			filter: {
				id: {
					_eq: validatedNumber,
				},
			}} : {}).then((a) => scenarioData = a.data);
					
		await directus.items('tasks').readMany().then((a) => taskData = a.data);
		return;
	}
	else{
		return;
	}
};

const taskDataAvailable = () => {
	let available = true;
	if(!taskData || taskData.length < 1){
		available = false;
	}
	return available;
};

const scenarioDataAvailable = () => {
	let available = true;
	if(!scenarioData || scenarioData.length < 1){
		available = false;
	}
	return available;
};

export {scenarioData, taskData, initializeData, taskDataAvailable, scenarioDataAvailable};
