import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import Plausible from 'plausible-tracker';

const { trackPageview } = Plausible({
	domain:'ggbgame.tqwi.nl',
	hashMode: true,
});

trackPageview({
	url: location.hostname,
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
