import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../../context/global';
import { useTextContext } from '../../../context/text/TextContext';
import { DecisionData, decisions } from '../../../data/decisions';
import { taskData } from '../../../data/scenarioData';
import BasicButton from '../../Buttons/BasicButton';
import BasicFrame from '../../Frames/BasicFrame';
import { Heading, Paragraph } from '../../Typography';
import Container from '../components/Container';
import {SCORM} from 'pipwerks-scorm-api-wrapper';

type scoreEntry = {
	description: string,
	percentage: number,
	correct: number,
	total: number
}

const ScoreScreen = () => {

	const {setGameState} = useGlobalContext();
	const {t} = useTextContext();

	const [scores, setScores] = useState<scoreEntry[]>([]);
	const [percentage, setPercentage] = useState<number>(0);

	useEffect(() => {
		const newScores: scoreEntry[] = [];
		taskData?.map((task) => {		
			const totals: DecisionData[] = [];
			const correctOnes: DecisionData[] = [];
			decisions.map((decision)=> {
				if(decision.task === task.id){
					totals.push(decision);
					if(decision.chosen_option === decision.correct_option){
						correctOnes.push(decision);
					}
				}
			});
			if(totals.length != 0)
				newScores.push({description: task.task_name ? task.task_name : '', percentage: correctOnes.length / totals.length * 100, correct: correctOnes.length, total: totals.length});
		});
		setScores(newScores);

	},[]);

	useEffect(() => {
		let total = 0;
		let correct = 0;
		scores.map((score, index) => {
			total = total + score.total;
			correct = correct + score.correct;
		});
		const newPercentage = correct/total*100;
		setPercentage(newPercentage);

	},[scores]);

	useEffect(() => {
		if(percentage >= 70 ){
			SCORM.set('cmi.completion_status', 'completed');
			SCORM.save();	

			console.log('completing scorm');
		} else {
			SCORM.set('cmi.completion_status', 'failed');
			SCORM.save();	
			console.log('failing scorm');

		}

		SCORM.get('cmi.completion_status');

	},[percentage]);

	const endGame = () => {
		//setGameState && setGameState(4);
		window.close();
	};
	return (  
		<Container>					
			<StyledFrame>	
				<FrameContent>
					<div>
						<Heading>{t('scoretitle1')}</Heading>
						<Paragraph>
							{t('score1')}						
						</Paragraph>				
						<br/>
						<ScoreContainer>
							<Heading>Score</Heading>
							{scores.map((score, index) => {
								return(
									<Flex key={`taskitem-${index}`}>
										<Paragraph style={{textAlign: 'left', width: '40%'}}>{score.description}</Paragraph>
										<ProgressionBox>
											<ProgressionBar percentage={`${score.percentage}%`} key={index}><div></div></ProgressionBar>
										</ProgressionBox >						
										<StyledParagraph>
											{score.correct}/{score.total}<br/> 
										</StyledParagraph>
									</Flex>);	
							})}
						</ScoreContainer>
						<br/>
					</div>
					<Paragraph>
						{percentage >= 70 ? t('scorescreen_positiveresult_text_01') : t('scorescreen_negativeresult_text_01')}	
					</Paragraph>					
				</FrameContent>					
			</StyledFrame>
			{/* <ButtonRow>		
				<BasicButton style={{ margin: '0!important', padding: '0!important'}} onClick={endGame}>{t('button.backtoportal.label')}</BasicButton>
			</ButtonRow> */}
		</Container>
	);
};

// styled components
const StyledParagraph = styled(Paragraph)`
	width: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledFrame = styled(BasicFrame)`
	position: relative;
	box-sizing: border-box;
	width: 100%;
	text-align: center;
	min-height: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const FrameContent = styled.div`
	position: relative;
	overflow-y: auto;
	max-height: 100%;

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	width: 100%;
`;

const ButtonRow = styled.div`	
	
	display: flex;
	width: 100%;
	min-height: 0;
	justify-content: center;
`;

const ScoreContainer = styled.div`	
	border: 1px solid rgba(155, 154, 154, 0.5);
	padding: 1%;
	margin: 1%;	
	display: flex;
	flex-direction: column;
	width: 80%;
`;

const Flex = styled.div`	
	display: flex;
	flex-direction: row;
`;


const ProgressionBox = styled.div`	
	display: flex;
	flex-direction: column;
	justify-content: center;
	width:50%;
`;

const ProgressionBar = styled.div<{percentage: string}>`	
	width: 100%;
	height: 50%;
	border: 1px solid white;
	border-radius: 25px;
	& > div {
		width: ${p => p.percentage == 0 ? '0%' : p.percentage};
		height: 100%;
		background-color:white;
		border-radius: 25px;
	}
`;

export default ScoreScreen;
