import { PartialItem } from '@directus/sdk';
import { DecisionData } from '../../data/decisions';
import { AvatarData, DialogueChunkData, DialogueChunkDialogueOptionsData, DialogueOptionData, directus, directus_url } from '../../services/directus';

export let dialogueChunks : undefined | PartialItem<DialogueChunkData>[] | null;
export let dialogueOptions : undefined | PartialItem<DialogueOptionData>[] | null;
export let dialogueRelations : undefined | PartialItem<DialogueChunkDialogueOptionsData>[] | null;
export let avatars : undefined | PartialItem<AvatarData>[] | null;

const initializeData = async () => {

	// load dialogue chunks
	await directus.items('dialogue_chunks').readMany({limit: -1}).then((a) => {
		dialogueChunks = a.data;
	});
	// load dialogue options
	await directus.items('dialogue_options').readMany({limit: -1}).then((a) => {
		dialogueOptions = a.data;
	});
	// load dialogue relations
	await directus.items('dialogue_chunks_dialogue_options').readMany({limit: -1}).then((a) => {
		dialogueRelations = a.data;
	});
	// load avatars
	await directus.items('avatars').readMany().then((a) => {
		avatars = a.data;
	});
	return;
};

export const dialogueList: DialogueList = {};

export const createDialogueList = () => {
	dialogueChunks?.map((chunk)=> {
		if(!chunk.key)return;

		const options: DialogueOptionData[] = [];
		const decision_data_options: {option: string, feedback: string}[] = [];

		chunk.enable_player_decision && chunk.player_decisions?.map((decision) => {
			if(decision && dialogueOptions && dialogueRelations){
				const option_id = dialogueRelations.find((entry) => {return entry.id === decision;})?.dialogue_options_id;
				const option_order = dialogueRelations.find((entry) => {return entry.id === decision;})?.order;
				const option = dialogueOptions.find((entry) => {return entry.id === option_id;});
				option && options.push({
					id: option.id ? option.id : 0,
					follow_up_chunk: option.follow_up_chunk ? option.follow_up_chunk : '',
					swap_pannellum_scene: option.swap_pannellum_scene ? option.swap_pannellum_scene : '',
					text: option.text ? option.text : '', // key of text in texts collection
					name: option.name ? option.name : '',
					feedback_text: option.feedback_text ? option.feedback_text : '',
					order: option_order,
				});
				decision_data_options.push({option: option?.text ? option.text : 'undefined option', feedback: option?.feedback_text ? option.feedback_text : 'undefined feedback text'});
			}
		}); 

		dialogueList[chunk.key] = {
			chunk : {
				key: chunk.key,
				text: chunk.text ? chunk.text : 'undefined chunk text',
				avatar: chunk.avatar,
				image: chunk.image ? chunk.image : null,
				enable_player_decision: chunk.enable_player_decision ? chunk.enable_player_decision : false,
				timer_time: chunk.timer_time ? chunk.timer_time : 0,
				follow_up_chunk: chunk.follow_up_chunk,
				swap_scene: chunk.swap_scene ? chunk.swap_scene : '',
				decision_description: chunk.decision_description,
				correct_decision: chunk.correct_decision ? chunk.correct_decision : 0,
				player_decisions: chunk.player_decisions,
				decisions_singlerow: chunk.decisions_singlerow ? true : false,
				task: chunk.task ? chunk.task : '',
				adc_enabled: chunk.adc_enabled ? true : false,
				adc: chunk.adc !== undefined ? chunk.adc : -1,
				end_game: chunk.end_game ? chunk.end_game : false,
				sound: chunk.sound ? chunk.sound : '',
				voice_over: chunk.voice_over ? chunk.voice_over : '',
				
				adc_correct: chunk.adc_correct ? chunk.adc_correct : '' , // correct answer
				adc_feedback_correct: chunk.adc_feedback_correct ? chunk.adc_feedback_correct : '',
				adc_feedback_wrong: chunk.adc_feedback_wrong ? chunk.adc_feedback_wrong : '',
				adc_task: chunk.adc_task ? chunk.adc_task : '',
				adc_description: chunk.adc_description ? chunk.adc_description : '',
				adc_followup_correct: chunk.adc_followup_correct ? chunk.adc_followup_correct : '',
				adc_followup_wrong: chunk.adc_followup_wrong ? chunk.adc_followup_wrong : '',

			},
			//options.length > 0 ? options : null,
			options: options,
			decision: {
				description: chunk.decision_description ? chunk.decision_description : 'invalid description',
				options: decision_data_options,
				correct_option: chunk.correct_decision ? chunk.correct_decision : -1,
				chosen_option: null,
				task: chunk.task ? chunk.task : '',
				adc: false
			},
		};
	});

	return;
};
export const avatarList: AvatarList = {};

export const createAvatarList = () => {
	avatars?.map((avatar)=> {
		if(!avatar.id)return;

		let imagePath;

		if(process.env.NODE_ENV === 'development'){
			// only usable with CMS
			// imagePath = `${directus_url}assets/${avatar.avatar_image}`;
			imagePath = `https://ggbgame.tqwi.nl/static/renders/${avatar.avatar_image}`;
		}
		else{
			imagePath = `${process.env.PUBLIC_URL}/static/renders/${avatar.avatar_image}`;
		}

		avatarList[avatar.id] = {
			id : avatar.id,
			name: avatar.name ? avatar.name : '',
			avatar_image: imagePath,
		};
	});

	return;
};

// types

export type DialogueData = {
	chunk: DialogueChunkData;
	options: DialogueOptionData[];
	decision: DecisionData | null; // replace with decisiondata later on
}

export type DialogueList = {
	[Key in string]?: DialogueData;
}

export type AvatarList = {
	[Key in string]?: AvatarData;
}

export {initializeData};
