//#region classes
class Directer {
	constructor() {
		this._items = {};
	}
	items(collection) {
		return this._items[collection] || (this._items[collection] = new ItemsHandler(collection));
	}
	get files() {
		return this._files || (this._files = new FilesHandler(this.transport));
	}
}

class ItemsHandler {
	constructor(collection) {
		this.endpoint = collection.startsWith('directus_') ? `./files/${collection.substring(9)}` : `./items/${collection}`;
	}

	async readMany(query){
		return getData(query, this.endpoint);
	}

	async readByQuery(query){
		return getData(query, this.endpoint);
	}
}

class FilesHandler extends ItemsHandler {
	constructor(transport) {
		super('directus_files', transport);
	}
}

//#endregion 

//#region functions

function getData(query, endpoint){
	let field = null;
	let functie = null;
	let arg = null;

	if(query){
		if(query.filter){
			//field: id, name
			field = Object.keys(query.filter);
			if(query.filter[field]){
				//functie (_eq, _in)
				functie = Object.keys(query.filter[field]);
				//argument, value
				arg = query.filter[field][functie];
			}
		}
	}	
	
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const unfilteredData = require(endpoint+'.json');
	function filter() {
		if(functie){
			if(functie == '_eq'){
				return _eq(unfilteredData, field, arg);
			} else {
				return _in(unfilteredData, field, arg);
			}
		} else {
			return unfilteredData;
		}
	}	
	const data = filter();

	return {
		data
	};
}

function _eq(data, field, arg) {
	const newData = data.filter(item => item[field] == arg);
	return newData;
}

function _in(data, field, arg) {
	const newData = [];
	data.map((item) => {
		arg.map((argItem) => {
			if(item[field] == argItem){
				newData.push(item);
			}
		});
	});		
	return newData;
}

//#endregion

export {Directer};