import Console from './components/Console';
import Dialogue from './components/Dialogue/Dialogue';
import ForceLandscape from './components/ForceLandscape';
import Input from './components/Input/Input';
import LoadingScreen from './components/Screens/LoadingScreen/LoadingScreen';
import { GlobalContextProvider } from './context/global';
import { TextContextProvider } from './context/text/TextContext';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from './style';
import ButtonInterface from './components/ButtonInterface/index';
import Tutorial from './components/Tutorial/Tutorial';
import Screens from './components/Screens/Screens';
import Taakkaart from './components/Screens/TaskCardScreen/TaskCardScreen';
import Pannellum from './components/Pannellum/Pannellum';
import DialogueImageMobile from './components/DialogueImageMobile/DialogueImageMobile';
import InterfaceSpace from './components/InterfaceSpace/InterfaceSpace';
import Mutebutton from './components/Buttons/Mutebutton';
import { AudioContextProvider } from './context/audio';
import {SCORM} from 'pipwerks-scorm-api-wrapper';
import { useEffect } from 'react';

function App() {

	useEffect(() => {
		process.env.NODE_ENV === 'production' && SCORM.init();
		// SCORM.set('cmi.completion_status', 'completed');
		// SCORM.save();
	}, []);
	
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle/>
			<AudioContextProvider>
				<GlobalContextProvider>
					<LoadingScreen/>
					<TextContextProvider>
						<Screens/>
						<Tutorial/>
						<ForceLandscape/>
						{/* { !process.env.NODE_ENV || process.env.NODE_ENV === 'development' &&
							<Console/>			
						} 						 */}
						<Console/>
						<Input/>
						<Mutebutton/>
						<Pannellum/>
						<InterfaceSpace>
							<Dialogue/>
							<ButtonInterface/>
						</InterfaceSpace>
						<Taakkaart/>
						<DialogueImageMobile/>
					</TextContextProvider>
				</GlobalContextProvider>				
			</AudioContextProvider>
		</ThemeProvider>
	);
}

export default App;
