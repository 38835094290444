import { DefaultType, Directus, ID, PartialItem } from '@directus/sdk';
import { Directer } from './directer/directer.js';

export const use_cms = false; //change to false for local data

export const directus_url = 'https://tqwi-p21-04.directus.app/';
export const directus_auth_token = 'j1bberj3bber';

export let directus;

if(use_cms){
	directus = new Directus<DirectusProps>(directus_url, {auth: {staticToken: directus_auth_token}});
} 
else {
	directus = new Directer<DirectusProps>();
}

export type DirectusProps = {

	// Pannellum

	pannellum_scenes: PannellumSceneData,
	pannellum_hotspots: PannellumHotspotData,
	pannellum_scenes_pannellum_hotspots: PannellumScenePannellumHotspotsData,

	// Dialogue

	dialogue_chunks: DialogueChunkData,
	dialogue_options: DialogueOptionData,
	dialogue_chunks_dialogue_options: DialogueChunkDialogueOptionsData,

	scenario_data : ScenarioData
	texts_translations: TextTranslationData,
	
	scenarios_directus_files: ScenariosFilesData,

}

// #region Dialogue

export type DialogueChunkData = {
	key: string,
	text: string, // key of text in texts collection
	avatar: ID | null | undefined,
	image: string | null,
	enable_player_decision: boolean,
	timer_time: number,
	follow_up_chunk: string | null | undefined,
	swap_scene: string | null,
	decision_description: string | null | undefined,
	correct_decision: number,
	player_decisions: ID[] | null | undefined, // id to relation
	decisions_singlerow: boolean,
	task: ID,

	// ADC 
	adc_enabled: boolean,
	adc: number,
	adc_correct: string, // correct answer
	adc_feedback_correct: string,
	adc_feedback_wrong: string,
	adc_task: ID,
	adc_description: string,
	adc_followup_correct: ID,
	adc_followup_wrong: ID,

	end_game: boolean,
	sound: string,
	voice_over: string
}

export type DialogueOptionData = {
	id: ID,
	follow_up_chunk: string | null,
	swap_pannellum_scene: string | null,
	text: string, // key of text in texts collection
	name: string,
	feedback_text: string,
	order: number | undefined,
}

export type DialogueChunkDialogueOptionsData = {
	id: ID,
	dialogue_chunks_key: string,
	dialogue_options_id: ID,
	order: number,
}

export type AvatarData = {
	id: ID,
	name: string,
	avatar_image: string,
}

//#endregion
// #region Pannellum

export type PannellumSceneData = {
	starting_dialogue: string | undefined,
	hotspots: (ID | undefined)[] | undefined,
	scene_id: ID,
	key: string,
	render: string,
	customConfig: string, // JSON String
	pitch: number,
	yaw: number,
	scenario: number
	decision_group_toggle: boolean,
	description: string,
	correct_option: number,
	task: ID,
	porto_toggle: boolean,
	porto_dialogue: string,
	sound: string,
	background_sound: string
}

export type PannellumScenePannellumHotspotsData = {
	id: ID,
	pannellum_scenes_id: ID,
	pannellum_hotspots_id : ID,
}

export type PannellumHotspotData = {
	id: ID,
	yaw: number, pitch: number,
	type: string,
	option_text: string | undefined,
	target_scene: string | undefined,
	target_dialogue: string | undefined,
	target_overlay: number | undefined,
	feedback: string | undefined
}

//#endregion

export type ScenarioData = {
	id: ID,
	name: string,
	starting_scene: string | undefined,
	tasks: TaskData[]
	taskcards: ID[]
	sound: string
}

export type ScenariosFilesData = {
	id: ID,
	scenarios_id: ID,
	directus_files_id: string
	order: number,
}

export type TaskData = {
	id: ID,
	label: string
	task_name: string
}

export type TextTranslationData = {
	id: ID,
	languages_code: string,
	text: string,
	texts_key: string,
}


export type FileData = {
    [key: string] : PartialItem<DefaultType>
}
